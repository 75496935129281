import modals from './modals'

const INPUT  = {
  PRODUCT_STAY_DATES : 'product_dates',
  PRODUCT_STAY_GROUP : 'product_group',
  PRODUCT_STAY_FEES : 'product_fees',
}

export const inputModal  = {
  [INPUT.PRODUCT_STAY_DATES] : modals.MODAL_PRODUCT_STAYDATES,
  [INPUT.PRODUCT_STAY_GROUP] : modals.MODAL_PRODUCT_STAYGROUP,
  [INPUT.PRODUCT_STAY_FEES] : modals.MODAL_PRODUCT_FEES,
}


export default {
  ...INPUT
}
