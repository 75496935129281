
export default {
    MODAL_SIGNUP : 'signupModal',
    MODAL_LOGIN : 'loginModal',
    MODAL_FORGOT_PASSWORD : 'forgotPasswordModal',
    MODAL_NEW_PASSWORD : 'newPasswordModal',
    MODAL_GENERIC : 'genericModal',

    // MR1
    MODAL_MR1 : 'MODAL_MR1',
    MODAL_STAYLOCALITIES : 'stayLocalitiesModal',
    MODAL_STAYDATES : 'stayDatesModal',
    MODAL_STAYGROUP : 'stayGroupModal',

    // FICHE
    MODAL_PRODUCT_STAYGROUP : 'MODAL_PRODUCT_STAYGROUP',
    MODAL_PRODUCT_STAYDATES : 'MODAL_PRODUCT_STAYDATES',
    MODAL_PRODUCT_FEES : 'MODAL_PRODUCT_FEES'
}
