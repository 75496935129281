import dayjs from 'dayjs'

/**
 *
 * @param date
 * @returns {boolean}
 */
export const isDateObject = function(date){
    return date instanceof Date
}
/**
 *
 * @param {string|Date} date
 * @returns {string}
 */
export const formatDMY = function (date) {
    if(!isDateObject(date)){
        date = new Date(date)
    }
    return dateToStringDMY(date)
}
/**
 *
 * @param {Date} date
 * @returns {string}
 */
export const dateToStringDMY = function (date){
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
}

export const isSameDay = function (a, b){
    return a.getMonth() === b.getMonth() && a.getDate() === b.getDate() && a.getFullYear() === b.getFullYear()
}
/**
 *
 * @param {Date} a
 * @param {Date} b
 * @return boolean
 */
export const isSameMonth = function (a, b){
    return a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()
}
/**
 *
 * @param {string} dateString
 * @param {string} separator
 * @returns {Date}
 */
export const stringYMDToDate = function (dateString, separator = '-'){
    const tabDate = dateString.split(separator);
    return new Date(tabDate[0],tabDate[1]-1,tabDate[2])
}
/**
 *
 * @param {string} dateString
 * @param {string} separator
 * @returns {Date}
 */
export const stringDMYToDate = function (dateString, separator = '-'){
    const tabDate = dateString.split(separator);
    return new Date(tabDate[2],tabDate[1]-1,tabDate[0])
}
/**
 *
 * @param {Date} dateStart
 * @param {Date} dateEnd
 * @returns {[Date]}
 */
export const getDaysBetween = function(dateStart, dateEnd){
    const dates = [];
    for(
        const dayDate = new Date(dateStart.getTime());
        dayDate <= dateEnd;
        dayDate.setDate(dayDate.getDate()+1)
    ){
        dates.push(new Date(dayDate))
    }
    return dates;
}
/**
 *
 * @param int dateUnix
 * @returns string
 */
export const unixToYYYYMMDD = (dateUnix) => {
    return dayjs(dateUnix).format("YYYY-MM-DD")
}
