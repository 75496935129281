import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dateTimeFormats from "./dateTimeFormats";
import numberFormats from './numberFormats'
import axios from 'axios'

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'nl',
    dateTimeFormats,
    numberFormats,
    silentTranslationWarn: true
});

export default i18n

/*
export default async function createI18n(lang="en"){
    const trad = await getTranslation(lang);

    const messages = {
        [lang] : trad
    };

    return new VueI18n({
        locale: lang,
        messages,
        dateTimeFormats,
        silentTranslationWarn: true
    });
}*/

export async function hydrateI18n(){
    const trad = await getTrad()
    i18n.setLocaleMessage('en',trad.en)
    i18n.setLocaleMessage('fr',trad.fr)
    i18n.setLocaleMessage('de',trad.de)
    i18n.setLocaleMessage('nl',trad.nl)
}
async function getTrad(){

    let trad = null;

    try{
        trad = await getDynamicTrad()
    }catch (e) {
        console.error("Traduction file request failed",e)
    }

    if(!isValidTradFile(trad)){
        return await getBackupTrad();
    }

    return trad;
}

function isValidTradFile(trad){
    if(!(typeof trad === 'object' && trad !== null)){
        return false;
    }
    if(Object.keys(trad).length < 4){
        return false;
    }
    return true;
}

async function getDynamicTrad(){
    const { data } = await axios(`/${__tradFile}`)
    return data
}
async function getBackupTrad(){
    const { data } = await axios("/trad-js-2.json")
    return data
}

export const setupI18n = async (lang) => {
    i18n.locale = lang
    await hydrateI18n();
}
