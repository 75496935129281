let isScriptAdded = false;
let isRecaptchaReady = false;

function addScript(src){
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    head.appendChild(script);
}

function captchaExist(){
    return typeof window.grecaptcha !== 'undefined' && typeof window.grecaptcha.ready === "function"
}

function setRecaptchaReadyWhenReady(){
    if(captchaExist()){
        window.grecaptcha.ready(() => {
            isRecaptchaReady = true
        })
        return;
    }

    setTimeout(setRecaptchaReadyWhenReady, 50);
}

export const setupRecaptchaIfNecessary = () => {
    if(isScriptAdded){
        return;
    }
    isScriptAdded = true

    const url = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
    addScript(url);
    setRecaptchaReadyWhenReady()

}

function recaptchaReady(){
    return new Promise((resolve) => {
        setupRecaptchaIfNecessary()

        function resolveWhenCaptchaReady(){
            if(isRecaptchaReady){
                resolve()
                return;
            }
            setTimeout(resolveWhenCaptchaReady, 100);
        }
        resolveWhenCaptchaReady()
    })
}

export default async function getToken(action = "subscribe"){
    await recaptchaReady()
    const token = await window.grecaptcha.execute(RECAPTCHA_KEY, { action });
    return token
}
