import C from 'Constants'
import {sendInterractionalEventGA4} from "Plugins/dataLayer/dataLayerHelper";

export default class dataLayerProduct {

  constructor(store) {
    this.store = store
    this.init()
  }
  init() {
    this.watchStore();
  }
  watchStore() {
    // Groupe
    this.store.watch(
      state => state.product.stayGroup,
      newStayGroup => this.stayGroupChange(newStayGroup),
      {deep : true})


    // Stay Type
    this.store.watch(
      state => state.product.stayType,
      newStayType => this.stayTypeChange(newStayType),
      {deep : true})

    // Stay Type
    this.store.watch(
        state => state.product.stayDate,
        newStayType => this.trackStayDateChange(),
        {deep : true})

    // openInput
    this.store.watch( state => state.product.openInput,
      newInput=> this.openInputChange(newInput),
      {deep : true})
  }
  stayGroupChange(stayGroup) {
    //console.log("stayGroupChange",stayGroup)
  }
  stayTypeChange(stayType) {
    sendInterractionalEventGA4({
      'site_section' : 'AE',
      'eventAction' : 'Formulaire',
      'eventLabel' :  'TypeSejour-' + stayType.type
    })
    trackNotContextualisedEventGA4(
        'mdv_cta',
        'mdv',
        'calendar_stay',
        'click',
        {}
    )
  }
  trackStayDateChange() {
    trackNotContextualisedEventGA4(
        'mdv_cta',
        'mdv',
        'calendar_date',
        'click',
        {}
    )
  }
  // A l'ouverture d'un popover ou modal sur mobile du calendrier, la compo groupe ou du détail du séjour
  openInputChange(input){
    switch (input){
      case C.PRODUCT_STAY_DATES:
        sendInterractionalEventGA4({
          'site_section' : 'AE',
          'eventAction' : 'Clic',
          'eventLabel' :  'Choisir autre période - Haut'
        })
        break;
      case C.PRODUCT_STAY_GROUP:
        sendInterractionalEventGA4({
          'site_section' : 'AE',
          'eventAction' : 'Clic',
          'eventLabel' :  'Personnes'
        })
        trackNotContextualisedEventGA4(
            'mdv_cta',
            'mdv_booking',
            'group',
            'click',
            {}
        )
        break;
      case C.PRODUCT_STAY_FEES:
        sendInterractionalEventGA4({
          'site_section' : 'AE',
          'eventAction' : 'Clic',
          'eventLabel' :  'plus d\'info prix total'
        })
        trackNotContextualisedEventGA4(
            'mdv_cta',
            'mdv_booking',
            'price_details',
            'click',
            {}
        )
        break;
    }
  }
}
