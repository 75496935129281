import M from "Constants/mutations-types";

export const defaultState = {
    data : [],
    loading: false,
    selectedLocalityId : null // null = pas choisi, false = pas de preference, sinon un id
}
export const getters = {
    stayLocalitiesAsArray({data}){
        return Object.keys(data).map(id => ({id,...data[id]}))
    },
    selectedLocality({selectedLocalityId, data}){
        return selectedLocalityId ? data[selectedLocalityId] : selectedLocalityId
    },
    noPreference({selectedLocalityId}){
        return selectedLocalityId === false
    },
    hasSelectedLocality({selectedLocalityId}){
        return selectedLocalityId !== null && selectedLocalityId !== false
    },
    hasCompleted(state, getters){
        return getters.hasSelectedLocality || getters.noPreference
    }
}

export const mutations = {
    [M.MR1_STAY_LOCALITIES_SELECTED_SET](state, id){
        state.selectedLocalityId = id
    },
    [M.MR1_STAY_LOCALITIES_SET_NO_PREFERENCE](state){
        state.selectedLocalityId = false
    },
    [M.MR1_STAY_LOCALITIES_SELECTED_UNSET](state){
        state.selectedLocalityId = null
    },
}
export const actions = {
    async updateLocalities({ state }, id){
        // const {  } = state
    },
    setSelected({commit, state}, id){
        if(!state.data || !state.data[id])
            console.warn(`Aucun localité trouvée avec l'id ${id}`)
        commit(M.MR1_STAY_LOCALITIES_SELECTED_SET, id)
    },
    setNoPreference({commit}){
        commit(M.MR1_STAY_LOCALITIES_SET_NO_PREFERENCE)
    },
    removeLocalitySelected({commit}){
        commit(M.MR1_STAY_LOCALITIES_SELECTED_UNSET)
    }
}

export default {
    namespaced : true,
    state: defaultState,
    mutations,
    actions,
    getters
}
