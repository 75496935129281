import M from 'Constants/mutations-types';

export const defaultFormValuePersonCount = 1;
export const defaultFormValueDogCount = 0;

export const defaultState = {
    personCount : null,
    dogCount : null,
    personCountMax : 60,
    dogCountMax : 4
}

export const getters = {
    stayGroup: ({personCount, dogCount}) => ({personCount, dogCount}),
    personCountOrDefault : ({personCount}) => {
        return personCount !== null
            ? personCount
            : defaultFormValuePersonCount
    },
    dogCountOrDefault : ({dogCount}) => {
        return dogCount !== null
            ? dogCount
            : defaultFormValueDogCount
    },
    hasCompleted({personCount}, getters){
        return personCount !== null
    }
}

function isValidNumber(value){
    return !isNaN(parseInt(value))
}

export const mutations = {
    [M.MR1_STAYGROUP_SET_PERSONCOUNT](state, personCount){
        state.personCount = !isValidNumber(personCount)
            ? defaultState.personCount
            : personCount
    },
    [M.MR1_STAYGROUP_SET_DOGCOUNT](state, dogCount){
        state.dogCount = !isValidNumber(dogCount)
            ? defaultState.dogCount
            : dogCount
    },
    [M.MR1_STAYGROUP_RESET](state){
        Object.keys(defaultState).forEach(key => {
            state[key] = defaultState[key]
        })
    }
}

export const actions = {
    setPersonCount({commit}, personCount){
        commit(M.MR1_STAYGROUP_SET_PERSONCOUNT, personCount)
    },
    setDogCount({state, commit}, dogCount){
        // Si on veut setter un chien, il faut alors au minimum une personne
        if(state.personCount === null){
            commit(M.MR1_STAYGROUP_SET_PERSONCOUNT, 1)
        }
        commit(M.MR1_STAYGROUP_SET_DOGCOUNT, dogCount)
    },
    setGroup({commit}, {personCount, dogCount}){
        commit(M.MR1_STAYGROUP_SET_PERSONCOUNT, personCount)
        commit(M.MR1_STAYGROUP_SET_DOGCOUNT, dogCount)
    },
    setDefaultIfNothing({state : {dogCount, personCount},commit}){
        if(personCount === null){
            commit(M.MR1_STAYGROUP_SET_PERSONCOUNT, defaultFormValuePersonCount)
        }
        if(dogCount === null){
            commit(M.MR1_STAYGROUP_SET_DOGCOUNT, defaultFormValueDogCount)
        }
    },
    reset({commit}){
        commit(M.MR1_STAYGROUP_RESET)
    }
}

export default {
    namespaced : true,
    state : {...defaultState},
    mutations,
    actions,
    getters
}
