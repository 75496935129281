export default {
    props: {
        name: {
            Type: String
        },
        value : {
            Type : String
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
