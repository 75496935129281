import C from 'Constants'
import M from 'Constants/mutations-types'

import stayGroup from 'Store/product/stayGroup'
import staySelected from 'Store/product/staySelected'
import stayType from 'Store/product/stayType'
import availableStays from "Store/product/availableStays";
import stayDate from 'Store/product/stayDate'
const state = {

  fullscreenGallery: false,
  fullscreenPosition: 0,
  modalOpen : false,
  openInput : null,

  location_id : null,
  personCountMax : 15,
  dogCountMax : 1,

  openPriceBox : false
}

const getters = {
  wantedStay(state){
    const { start, end, type } = state.stayDate.stay
    const { personCount, dogCount } = state.stayGroup
    return {
      location_id : state.location_id,
      start,
      end,
      type,
      group : {
        personCount,
        dogCount
      }
    }
  },
  bookingUrl : (state) => {
    const stayId = state.staySelected.stay_id
    const { personCount, dogCount } = state.stayGroup
    return `/Reservation.do?IDSejour=${stayId}&nbrPers=${personCount}&nbrAnim=${dogCount}`;
  },
  isOpenInputDates : ({openInput}) => openInput === C.PRODUCT_STAY_DATES,
  isOpenInputGroup : ({openInput}) => openInput === C.PRODUCT_STAY_GROUP,
  isOpenInputFees : ({openInput}) => openInput === C.PRODUCT_STAY_FEES,

  canOpenPopover : (state, getters, rootState, rootGetters) =>  rootGetters['breakpoint/isNotMobile'] && rootGetters['modals/noModalOpen'],

  showPopoverDates : ({openInput}, getters) =>  getters['canOpenPopover'] && getters['isOpenInputDates'],
  showPopoverGroup : ({openInput}, getters) =>  getters['canOpenPopover'] && getters['isOpenInputGroup'],
  showPopoverFees : ({openInput}, getters) =>  getters['canOpenPopover'] && getters['isOpenInputFees'],

  isPriceBoxOpen : ({openPriceBox}, getters, rootState, rootGetters) =>  openPriceBox || !rootGetters['breakpoint/isMobile'],

}
const mutations = {
  [M.PRODUCT_OPEN_INPUT](state, input){
    state.openInput = input
  },
  [M.PRODUCT_CLOSE_INPUT](state){
    state.openInput = null
  },
  [M.PRODUCT_OPEN_MODAL](state){
    state.modalOpen = true
  },
  [M.PRODUCT_CLOSE_MODAL](state){
    state.modalOpen = false
  },
  [M.PRODUCT_OPEN_FULLSCREEN_GALLERY](state, position=0){
    state.fullscreenGallery = true;
    state.fullscreenPosition = position;
  },
  [M.PRODUCT_CLOSE_FULLSCREEN_GALLERY](state){
    state.fullscreenGallery = false
  },
  [M.PRODUCT_PRICEBOX_TOGGLE](state){
    state.openPriceBox = !state.openPriceBox
  },
}
const actions = {
  openInput : ({commit},{input}) => commit(M.PRODUCT_OPEN_INPUT, input),
  closeInput : ({commit}) => commit(M.PRODUCT_CLOSE_INPUT),

  openFullscreenGallery : ({commit},{position}) => commit(M.PRODUCT_OPEN_FULLSCREEN_GALLERY, position),
  closeFullscreenGallery : ({commit}) => commit(M.PRODUCT_CLOSE_FULLSCREEN_GALLERY),

  openInputDates : ({dispatch}) => dispatch('openInput', { input : C.PRODUCT_STAY_DATES }),
  openInputGroup : ({dispatch}) => dispatch('openInput', { input : C.PRODUCT_STAY_GROUP }),
  openInputFees : ({dispatch}) => dispatch('openInput', { input : C.PRODUCT_STAY_FEES }),

  togglePriceBox : ({commit}) => commit(M.PRODUCT_PRICEBOX_TOGGLE),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules : {
    staySelected,
    stayGroup,
    stayType,
    availableStays,
    stayDate
  }
}
