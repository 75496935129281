const ITEM_NAME = 'persistantStore';

export const set = (data) => {
  const current = get()
  const newVal = {...current, ...data}
  localStorage.setItem(ITEM_NAME, JSON.stringify(newVal));
}

export const get = () => {

  const val = localStorage.getItem(ITEM_NAME)
  if(val){
    return JSON.parse(val)
  }

  return {}
}
