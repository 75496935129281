import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

import 'dayjs/locale/fr'
import 'dayjs/locale/nl'
import 'dayjs/locale/de'

export const setupDayjs = (lang) => {
    dayjs.locale(lang)
    dayjs.extend(localeData)
}
