import M from 'Constants/mutations-types'

const state = {
  type : "wk",
}

const getters = {}
export const mutations = {
  [M.PRODUCT_STAYTYPE_SET](state, type){
    state.type = type
  },
}
export const actions = {
  setStayTypeSelected({commit}, type){
    commit(M.PRODUCT_STAYTYPE_SET, type)
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}
