<template>
    <input type="hidden" name="recaptcha" :value="token"/>
</template>

<script>
    import getToken from "Plugins/recaptcha";

    export default {
        name: "Recaptcha",
        data : () => {
            return {
                token : null
            }
        },
        props : {
            action : {
                type : String,
                default : 'subscribe'
            }
        },
        async created(){
            this.token = await getToken()
        }
    }
</script>
