let serverData;

export function getServerData(){
    if(!serverData) {
        const serverDataText = document.getElementById("server-data").innerHTML;
        serverData = serverDataText
            ? JSON.parse(serverDataText)
            : {}
    }
    return serverData;
}
