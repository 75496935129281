import Vue from 'vue'
import store from 'Store'
import { getServerData } from "./server-data"

import { setupI18n } from "Plugins/i18n/i18n"
import { setupDayjs } from 'Plugins/dayjs'
import { setupStore } from 'Store/helpers'

import { handleError, setupErrorHandler } from "Plugins/errorHandler";

import i18n from "Plugins/i18n";
import Global from "Classes/container/Global";
import Tooltip from "Components/ui/Tooltip";

/**
 * Initialise les scripts, plugins,.. qui sont commun à toutes les pages
 * @returns {Promise<{data, store: *, i18n}>}
 */
export async function defaultSetup(){

    const { store : serverStoreData, ...otherServerData } = getServerData()

    setupErrorHandler()
    setupStore(store, serverStoreData)
    await setupI18n(store.state.lang)
    setupDayjs(store.state.lang)


    store.dispatch("base/pageLoaded")

    return {
        store,
        i18n,
        data : otherServerData
    }
}

export function initGlobal({i18n, store, data}){
    new Global({
        i18n,
        store,
        data
    })
}

/**
 * Fonction qui encapsule le script executé sur une page
 * Permet de regrouper l'initialisation des plugins/scripts et la gestion d'erreur
 * @returns {Promise<void>}
 * @param {function} wrappedFunctionToCall - la function a executer
 */
export async function commonWrapper(wrappedFunctionToCall){
    try{

        const {
            store,
            i18n,
            data
        } = await defaultSetup();

        initGlobal({
            store,
            i18n,
            data
        })

        wrappedFunctionToCall({
            store,
            i18n,
            data
        })

    } catch (err) {
        handleError(err)
    }
}

export function initTooltip() {

    document.querySelectorAll(".tooltip-vue").forEach((tooltip) => {

        new Vue({
            el: tooltip.id,
            render: function(createElement) {
                return createElement(Tooltip, {
                    props: {
                        title: tooltip.dataset.title,
                        id: tooltip.id
                    }
                });
            },
            components: { Tooltip }
        });
    });
}
