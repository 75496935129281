import M from 'Constants/mutations-types'
import {getProperPageNameForGA} from "Plugins/dataLayer/dataLayerUtils";

export default class datalayerLiveChat {

    constructor(store) {
        this.store = store
        this.init()
        this.pageType = this.store.state.page.page
    }
    init() {
        this.listenMutations();
        this.watchStore();
    }
    listenMutations() {
        this.store.subscribe((m, state) => {
            try {
                const mutationPath = m.type.split('/')
                let mutation = mutationPath[mutationPath.length -1]
                switch (mutation) {
                    case M.LIVECHAT_LAUNCH_GREETING:
                        this.trackGreeting(getProperPageNameForGA(this.pageType))
                        break;
                }
            } catch(e) {
                throw new Error(`Error dataLayerPlugin : ${e}`)
            }
        })
    }
    watchStore() {
        // Groupe
        this.store.watch(
            state => state.liveChat.lastLiveChatId,
            newLastLiveChatId => this.trackOuvertureLivechat(newLastLiveChatId, getProperPageNameForGA(this.pageType)))
    }
    trackGreeting(page) {
        dataLayer.push({
            'event': 'event_in_code',
            'custom_value_AE': {
                'gacategory' : "LiveChat",
                'gaaction' : page,
                'galabel' : "Ouverture livechat inactivite"
            }
        });
    }

    trackOuvertureLivechat(newLastLiveChatId, page) {
        dataLayer.push({
            'event': 'event_in_code',
            'custom_value_AE': {
                'gacategory' : "LiveChat",
                'gaaction' : page,
                'galabel' : "Ouverture"
            }
        });
    }
}