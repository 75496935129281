import M from "Constants/mutations-types"
import dayjs from "dayjs"

export const defaultState = {
    date : null
}
export const getters = {
    showDate : ({date}, getters, rootState, rootGetters) => {
        if (date) {
            return date
        }
        if (rootState.mr1.stayDates.isLoaded) {
            return rootGetters['mr1/stayDates/minStayStartDate']
        }
        return dayjs().format("YYYY-MM-DD")
    }
}

export const mutations = {
    [M.MR1_SHOW_DATE_SET](state, date){
        state.date = date
    },
}
export const actions = {
    setDate({commit}, date){
        commit(M.MR1_SHOW_DATE_SET, date)
    }
}

export default {
    namespaced : true,
    state: defaultState,
    mutations,
    actions,
    getters
}
