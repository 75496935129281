import C from 'Constants'
import Cookies from 'js-cookie'
import M from "Constants/mutations-types";

export const PAGES_WITH_LIVECHAT_WITHOUT_CONDITION = [
    C.PAGE_PRODUCT,
    C.PAGE_BOOKING
]
export const BIG_CAPACITY = 12
export const GROUP_ID = {
    "nl" : 1,
    "fr" : 2,
    "de" : 3,
    "en" : 4,
    RESEARCH_PAGE : {
        "nl" : 6,
        "fr" : 7,
        "de" : 8,
        "en" : 9
    },
    PRODUCT_PAGE_BIG_CAPACITY_RESEARCH : {
        "nl" : 10,
        "fr" : 11,
        "de" : 12,
        "en" : 13
    },
}
export const GREETING_TYPE = {
    BOOKING_PAGE : 'InactiveResa',
    PRODUCT_PAGE_BIG_CAPACITY_RESEARCH : 'FMGrandeCap'
}

export const defaultState = {
    lastLiveChatId : null,
    agents_available: false,
    visitor_engaged: false,
    greetingLaunch : null,
}

export function getGroupDefault(lang){
    return GROUP_ID[lang]
}
export function getGroupResearchPage(lang){
    return GROUP_ID.RESEARCH_PAGE[lang]
}
export function getGroupProductPageBigCapacityResearch(lang){
    return GROUP_ID.PRODUCT_PAGE_BIG_CAPACITY_RESEARCH[lang]
}

export const getters = {
    paramsForAPI : (state, getters, rootState, rootGetters) => {

        const params = [
            { name: 'Langue', value: rootState.base.lang.toUpperCase() },
            { name: 'PageType', value: rootState.page.page }
        ]

        if(rootGetters['auth/isLoggedIn']){
            const { id, phoneNumber, name, lastName, email, bookings_id } = rootState.auth.user
            params.push({ name: 'IDClient', value: id })
            params.push({ name: 'Telephone', value: phoneNumber })
            params.push({ name: 'Prenom Nom', value: `${name} ${lastName}`})
            params.push({ name: 'Email', value: email })

            if(Array.isArray(bookings_id))
                params.push({ name: 'IDResa', value: bookings_id.join(",") })
        }

        if(state.greetingLaunch !== null){
            params.push({ name: state.greetingLaunch, value: 1})
        }

        return params
    },
    assignedGroupOrNewGroup : (state, getters) => {
        if(getters.hasAssignedGroup){
            return getters.assignedGroup
        }
        return getters.newGroup
    },
    newGroup : (state, getters, rootState, rootGetters) => {
     //   return 5;
        const lang = rootState.base.lang

        if(rootGetters['page/onResearch']) {
            return getGroupResearchPage(lang);
        } else if(rootGetters['page/onProduct'] && rootState.mr1.stayGroup.personCount >= BIG_CAPACITY) {
            return getGroupProductPageBigCapacityResearch(lang)
        } else {
            return getGroupDefault(lang);
        }

    },
    isAssignedGroupAProductPageBigCapacityResearchGroup : (state, getters) => {
        return Object.keys(GROUP_ID.PRODUCT_PAGE_BIG_CAPACITY_RESEARCH)
            .some(lang => GROUP_ID.PRODUCT_PAGE_BIG_CAPACITY_RESEARCH[lang] === getters.assignedGroupOrNewGroup)
    },
    hasAssignedGroup : (state, getters) => getters.assignedGroup !== undefined,
    assignedGroup : () => Cookies.get("groupLiveChat") !== undefined ? parseInt(Cookies.get("groupLiveChat")) : Cookies.get("groupLiveChat"),
    onPageWithLiveChat : (state, getters, rootState, rootGetters) => (
        PAGES_WITH_LIVECHAT_WITHOUT_CONDITION.includes(rootState.page.page)
        ||
        ( rootGetters['page/onAccount'] && rootState.auth.user.hasJustMadeABooking )
    ),
    showLiveChatBar : ({visitor_engaged}, getters, rootState, rootGetters) => (
      ( getters['onPageWithLiveChat'] || visitor_engaged )
      && !rootGetters['modals/isModalOpen']
      && !(rootGetters['product/isPriceBoxOpen'] && rootGetters['breakpoint/isMobile'])
    )

}

export const mutations = {
    [M.LIVECHAT_START] (state, id){
        state.lastLiveChatId = id
    },
    [M.LIVECHAT_AGENTS_ONLINE](state){
        state.agents_available = true
    },
    [M.LIVECHAT_AGENTS_OFFLINE](state){
        state.agents_available = false
    },
    [M.LIVECHAT_VISITOR_ENGAGED](state){
        state.visitor_engaged = true
    },
    [M.LIVECHAT_VISITOR_NOT_ENGAGED](state){
        state.visitor_engaged = false
    },
    [M.LIVECHAT_LAUNCH_GREETING](state, type){
        state.greetingLaunch = type
    },
}

export const actions = {
    start : ({commit}, id) => commit(M.LIVECHAT_START, id),
    agentsOnline : ({commit}) => commit(M.LIVECHAT_AGENTS_ONLINE),
    agentsOffline : ({commit}) => commit(M.LIVECHAT_AGENTS_OFFLINE),
    visitorEngaged : ({commit}) => commit(M.LIVECHAT_VISITOR_ENGAGED),
    visitorNotEngaged : ({commit}) => commit(M.LIVECHAT_VISITOR_NOT_ENGAGED),
    launchGreetingBooking : ({commit}) => commit(M.LIVECHAT_LAUNCH_GREETING,GREETING_TYPE.BOOKING_PAGE),
    launchGreetingProductBigCapacityResearch : ({commit}) => commit(M.LIVECHAT_LAUNCH_GREETING,GREETING_TYPE.PRODUCT_PAGE_BIG_CAPACITY_RESEARCH),

}

export default {
    namespaced : true,
    state : defaultState,
    getters,
    mutations,
    actions
}
