import M from "Constants/mutations-types";

const defaultTypeForm = "wk"

export const defaultState = {
    type : null,
    start : null,
    end : null,
    month : null
}

export const getters = {
    selectedTypeOrDefault({type}){
        return type || defaultTypeForm
    },
    isSomethingCompleted : ({start, end, type, month}) => {
        return start !== null || end !== null || type != null || month != null
    },
    getStayDate : ({type, start, end, month}) => ({type, start, end, month}),
    stayDateSelectedWithDefaultType : ({start, end, month}, getter) => {
        const type = getter['selectedTypeOrDefault']
        return ({type, start, end, month})
    },
}

export const mutations = {
    [M.MR1_STAYDATE_TYPE_SELECTED_SET](state, type){
        state.type = type
    },
    [M.MR1_STAYDATE_SELECTED_SET](state, {start , end, type}){
        state.start = start
        state.end = end
        state.type = type
    },
    [M.MR1_STAYDATE_SELECTED_RESET](state){
        state.start = null
        state.end = null
        state.type = null
        state.month = null
    },
    [M.MR1_STAYDATE_REMOVE_DATE](state){
        state.start = null
        state.end = null
    },
    [M.MR1_STAYDATE_SET_MONTH](state, month){
        state.month = month
    },
    [M.MR1_STAYDATE_REMOVE_MONTH](state){
        state.month = null
    }
}

export const actions = {
    setStayTypeSelected({state, commit}, type){
        if(type !== state.type){
            commit(M.MR1_STAYDATE_TYPE_SELECTED_SET, type)
            // Type séjour change -> on ne garde que ça, on supprime les dates.
            commit(M.MR1_STAYDATE_REMOVE_DATE)
            commit(M.MR1_STAYDATE_REMOVE_MONTH)
        }
    },
    setDate({ commit }, payload){
        commit(M.MR1_STAYDATE_REMOVE_MONTH)
        commit(M.MR1_STAYDATE_SELECTED_SET,payload)
    },
    reset({ commit }){
        commit(M.MR1_STAYDATE_SELECTED_RESET)
    },
    setDefaultStayTypeIfNothing({state : {type}, dispatch}){
        if(!type){
            dispatch('setStayTypeSelected', defaultTypeForm)
        }
    },
    setMonth({ commit, dispatch }, payload){
        commit(M.MR1_STAYDATE_REMOVE_DATE)
        dispatch('setDefaultStayTypeIfNothing')
        commit(M.MR1_STAYDATE_SET_MONTH,payload)
    },
    removeMonth({ commit }){
        commit(M.MR1_STAYDATE_REMOVE_MONTH)
    },
}

export default {
    namespaced : true,
    state : defaultState,
    mutations,
    actions,
    getters
}
