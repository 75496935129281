import Vue from 'vue'
import Vuex from 'vuex'
import mr1 from './mr1'
import mr2 from './mr2'
import favorites from "./favorites"
import stayTypes from "./stayTypes"
import modals from './modals'
import breakpoint  from './breakpoint'
import auth from './auth'
import toaster from "./toaster"
import base from "./base"
import loader from "./loader"
import page from "./page"
import liveChat from "./liveChat"
import links from "./links"
import product from './product'

Vue.use(Vuex);

const state = {}
const mutations = {}
const getters = {
    dataForDebug : (state, getters, rootState, rootGetters) => {
        const { mr1, mr2,favorites, modals, breakpoint , base, loader, page,liveChat } = rootState

        return {
            mr1,
            mr2,
            favorites,
            modals,
            breakpoint,
            base,
            loader,
            page,
            liveChat
        }
    },
    paramsForResearch : (state, getters, {mr1, mr2}, rootGetters) => {
        const {
            type : stayType,
            start : startDate,
            end : endDate,
            month : monthDate
        } = rootGetters['mr1/stayDateSelected/getStayDate']

        return {
            filtersMR2 : mr2.filters,
            personCount : mr1.stayGroup.personCount,
            dogCount : mr1.stayGroup.dogCount,
            // On retourne soit la localité choisie OU le dossier initial
            folderId : mr1.stayLocalities.selectedLocalityId || mr1.folder.selectedFolderId,
            stayType,
            startDate,
            endDate,
            monthDate
        }
    }
}

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules : {
        mr1,
        mr2,
        favorites,
        stayTypes,
        modals,
        breakpoint,
        auth,
        toaster,
        base,
        loader,
        page,
        liveChat,
        links,
        product
    },
    state,
    getters,
    mutations
});

window.AEStore = store
export default store
