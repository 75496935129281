import axios from 'axios'
import M from "../../../constants/mutations-types";
import { wrappedFetchStayMonths } from "API"

export const state = {
    data : {
        '2020-01-01' : {},
        '2020-02-01': {},
        '2020-03-01': {},
        '2020-04-01': {},
        '2020-05-01': {},
        '2020-06-01': {},
    },
    isLoading : false
}

function monthIdAsText(){

}

export const getters = {
    stayMonthsQuery : ({
       localityId,
       adultCount,
       dogCount,
       stayDateSelected,
       stayTypeSelected: stayType
    })  => {
        return {
            localityId,
            adultCount,
            dogCount,
            stayDateSelected,
            stayType
        }
    }
}

export const mutations = {
    [M.MR1_STAYMONTHS_FETCH_START](state){
        state.stayMonthsIsLoading = true
    },
    [M.MR1_STAYMONTHS_FETCH_END](state){
        state.stayMonthsIsLoading = false
    },
    [M.MR1_STAYMONTHS_SET_DATA](state, {data}){
        state.months = data
    }
}

export const actions = {
    async updateStayMonths({ state, commit, getters, dispatch }){

        if(state.stayMonthsIsLoading)
            return;

        commit(M.MR1_STAYMONTHS_FETCH_START);

        const query = getters.stayMonthsQuery(state);
        const { data } = await wrappedFetchStayMonths(query)

        commit(M.MR1_STAYMONTHS_FETCH_END);
        commit(M.MR1_STAYMONTHS_SET_DATA, data);

    }
}
export default {
    namespaced : true,
    state,
    mutations,
    actions,
    getters
}
