import modals from './modals';
import mr1 from './mr1';
import breakpointsName from "./breakpointsName";
import pages from './pages'
import environment from "Constants/environment";
import product from 'Constants/product';

export default {
    ...modals,
    ...mr1,
    ...breakpointsName,
    ...pages,
    ...environment,
    ...product
}
