import store from "Store/store"
import Cookies from "js-cookie";

export const setSearchNumberCookie = function(){
    Cookies.set('SearchNumber', store.state.mr1.SearchNumber)
}

export const setCurSearchNumberOnFocus = () => {
    window.addEventListener('focus',setSearchNumberCookie)
}
