import M from 'Constants/mutations-types'

const state = {
    filters: [],
}

const getters = {}

const mutations = {
    [M.MR2_FILTER_ADD] : ({filters}, filter) => {
        if(!filters.includes(filter))
            filters.push(filter)
    },
    [M.MR2_FILTER_REMOVE] : (state, filterToRemove) => {
        state.filters = state.filters.filter(curFilter => curFilter !== filterToRemove)
    }
};

const actions = {
    add : ({commit},filter) => commit(M.MR2_FILTER_ADD, filter),
    remove : ({commit},filter) => commit(M.MR2_FILTER_REMOVE, filter)
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions,
};
