import axios from 'axios'
import { wrapRequest } from './helpers'
import store from "Store";
import qs from 'querystring'

export const addAPIFavorites = (data) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const what="update";
    const requestBody = qs.stringify({...data, what, tokenForm : store.state.auth.tokenForm})
    return axios.post('/favorisClient_Sejour.do', requestBody, config)
}

export const removeAPIFavorites = (data) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const what  ="delete";
    const requestBody = qs.stringify({...data, what, tokenForm : store.state.auth.tokenForm})
    return axios.post('/favorisClient_Sejour.do',requestBody, config)
}
