import BaseBind from "Utils/BaseBindClass"
import Glide, { Controls, Swipe, Keyboard } from '@glidejs/glide/dist/glide.modular.esm'
import {prevNextPerView} from 'Utils/glide-helpers'
import M from 'Constants/mutations-types'
import C from 'Constants'

export default class Slider extends BaseBind{
    constructor({ el, store, ga4DataForSlideEvent = {} }){
        super({ el, store }).init()
        store.subscribe((mutation, {breakpoint : {currentBreakpoint}}) => {
            if (mutation.type === `breakpoint/${M.BREAKPOINT_CHANGE}`) {
                this.onBreakpointChange()
            }
        });

        this.glide = new Glide(el,{
            startAt: 0,
            perView: this.getItemPerView(store.state.breakpoint.currentBreakpoint),
            gap:8,
            bound:true,
            perTouch: 1,
            endAt:0,
        });

        if(ga4DataForSlideEvent.eventName !== undefined) {
            const {eventName, ...rest} = ga4DataForSlideEvent
            this.glide.on('run', function () {
                trackBasicEventGA4(
                    eventName,
                    {
                        ...rest,
                    }
                )
            })
        }

        this.glide.mount({ Controls, Swipe, Keyboard })

        prevNextPerView(this.glide, this.getNbOfSlide())
    }
    getNbOfSlide(){
        return this.$el.querySelectorAll(".glide__slide").length;
    }
    getItemPerView = () => {
        let perView
        switch(this.store.state.breakpoint.currentBreakpoint){
            case C.xs: case C.sm:
                perView = 1.1
                break;
            case C.md:
                perView = 2
                break;
            default :
                perView = 3
        }
        return perView
    }
    onBreakpointChange = () => {
        this.glide.update({
            perView: this.getItemPerView(),
            perTouch:3
        })
    }
}
