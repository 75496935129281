import login from "Store/auth/login"

const state = {
    user : {
        id: null,
        name : null,
        lastName : null,
        email : null,
        phoneNumber : null,
        bookings_id : null,
        hasJustMadeABooking : false
    },
    tokenForm : null,
    isOwner : false,
}

const getters = {
    isLoggedIn : ({user : {id}}) => id !== null,
    userId : ({user : {id}}) => id
}
const mutations = {}
const actions = {}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions,
    modules : {
        login,
    }
}
