import M from 'Constants/mutations-types'
import {getAvailableStay} from "API/product";

const state = {
    stays : []
}

const getters = {}
export const mutations = {
    [M.PRODUCT_AVAILABLESTAYS_SET](state, stays, rootState){
        state.stays = stays
    },
}
export const actions = {
    async updateAvailableStays({commit, rootState : { product }}){
        // On garde une référence du stayType pour lequel on fait la demande de séjour
        const stayType = product.stayType.type
        const stays = await getAvailableStay({
            "TypeSejour" : product.stayType.type,
            "IDLocation" : product.location_id
        })

        // On vérifie que c'est toujours le même stayType, sinon on écrase pas les séjours actuel.
        if(product.stayType.type === stayType){
            commit(M.PRODUCT_AVAILABLESTAYS_SET, stays);
        }

    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
