<template>
    <b-form
            @submit="onSubmit"
            method="post"
            action="/hubspotSubscribe.do"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="formInscriptionNewsletter validate"
            novalidate>
        <input type="hidden" name="source" :value="source" />

        <div id="mc_embed_signup_scroll">

            <div class="mc-field-group">
                <b-form-input
                        type="text"
                        name="firstname"
                        v-model="PRENOM"
                        :placeholder="$t('__AEV5_Prénom')"
                        required />
            </div>

            <div class="mc-field-group">
                <b-form-input
                        type="email"
                        name="email"
                        v-model="EMAIL"
                        :state="emailState"
                        aria-describedby="input-live-help inputNewsletterInvalidMessage"
                        :placeholder="$t('__AEV5_adresse email')"
                        required />
                <b-form-invalid-feedback id="inputNewsletterInvalidMessage">
                    {{$t("__AEV5_L'email est obligatoire")}}
                </b-form-invalid-feedback>
            </div>
            <input type="submit" data-gacategory="Hp" data-gaaction="Bouton" data-galabel="Inscription newsletter" :value="$t('__AEV5_S’inscrire')" name="subscribe" id="mc-embedded-subscribe" class="secondary" />
        </div>
    </b-form>
</template>

<script>

    import {isValidEmail} from "Utils/validator";
    import { mapState } from 'vuex'
    import LastNameInput from "Components/ui/form/LastNameInput";
    import {BFormInput, BFormInvalidFeedback, BForm} from "bootstrap-vue";

    export default {
        name: "NewsletterForm",
        components: {
            BForm,
            BFormInput,
            LastNameInput,
            BFormInvalidFeedback
        },
        data(){
            return {
                EMAIL: null,
                PRENOM: null,
            }
        },
        props: {
            source: {
                type: String,
                default: 'newsletter'
            }
        },
        methods: {
            invalidForm(){
                if(this.emailState === null && this.EMAIL === null){
                    this.EMAIL = ''
                }
            },
            onSubmit (e) {
                if(!this.isFormValid) {

                    e.preventDefault();
                    this.invalidForm();
                }
            },
        },
        computed: {
            ...mapState('base',{
               tld : ({tld}) => tld,
               lang : ({lang}) => lang
            }),
            langUpperCase(){
                return this.lang.charAt(0).toUpperCase() + this.lang.slice(1);
            },
            emailState() {
                return this.EMAIL === null
                    ? null
                    : isValidEmail(this.EMAIL)
            },
            isFormValid(){
                return this.emailState
            },
        }
    }
</script>

<style scoped>

</style>
