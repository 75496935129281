<template>
    <b-form-group
            :label="$t('__AEV5_Votre nom')"
            :state="state">
        <b-form-input
                :autofocus="autofocus"
                type="text"
                :name="name"
                v-model="inputValue"
                :state="state"
                required />
    </b-form-group>
</template>

<script>
    import { BFormInput, BFormGroup } from 'bootstrap-vue'
    import InputDefaultBehavior from "Mixins/InputDefaultBehavior";

    export default {
        name: "LastNameInput",
        components:{
            BFormInput,
            BFormGroup
        },
        props:{
            autofocus : {
                type: Boolean,
                default: false
            }
        },
        mixins: [InputDefaultBehavior],
        computed: {
            state() {
                return this.value === null
                    ? null
                    : this.value.length >= 2
            }
        }
    }
</script>

<style scoped>

</style>
