import M from 'Constants/mutations-types'
import { getNbDaysBetweenToDates } from "Plugins/dataLayer/dataLayerUtils";

export default class datalayerFavorites {

    constructor(store) {
        this.store = store
        this.init()
    }
    init() {
        this.listenMutations();
    }
    listenMutations() {
        this.store.subscribe((m, state) => {
            try {
                const mutationPath = m.type.split('/')
                let mutation = mutationPath[mutationPath.length -1]
                switch (mutation) {
                    case M.FAVORITES_HOUSE_ADD:
                        this.addRemoveFavorite(
                            m.payload.Reference_AE,
                            getNbDaysBetweenToDates(m.payload.DateDebut, m.payload.DateFin),
                            'Add'
                        )
                        this.TrackFBAddFavorite(m.payload)
                        break;
                    case M.FAVORITES_HOUSE_REMOVE:
                        this.addRemoveFavorite(
                            m.payload.Reference_AE,
                            getNbDaysBetweenToDates(m.payload.DateDebut, m.payload.DateFin),
                            "Remove"
                        )
                        break;
                }
            } catch(e) {
                throw new Error(`Error dataLayerPlugin : ${e}`)
            }
        })
    }
    addRemoveFavorite(referenceAE, stayLength, type) {
        dataLayer.push({
            'event': 'GAEvent',
            'eventCategory': 'Wishlist',
            'eventAction': type,
            'eventLabel': '[' + referenceAE + ']',
            'eventValue': stayLength.toString()
        });
    }
    TrackFBAddFavorite(payload) {
        console.log(payload);
        dataLayer.push({
            'event': 'add_to_wishlist',
            'custom_value_AE': {
                'city' : payload.Region,
                'num_adults' : payload.NbrPers,
                'content_ids' : payload.Reference_AE,
                'add_to_wishlist_value' : parseInt(payload.price),
            }
        });
    }
}