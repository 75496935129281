import i18n from 'Plugins/i18n';

const state = {
    types : {
        'wk' : {},
        'lw' : {},
        'mw' : {},
        '1w' : {},
        '2w' : {}
    }
}

const getters = {
    stayTypeTxt() {
        return type => i18n.t(`__AEV5_type sejour_${type}`)
    },
    stayTypeAsOption : ({ types }, getters) => {
        return Object.keys(types).map(
            type => ({
                text : getters.stayTypeTxt(type),
                value : type
            })
        )
    }
}
const mutations = {}
const actions = {}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
