import debounce from 'lodash/debounce';
import axios from 'axios'
import objectAssignDeep from "Plugins/assignDeep";
import dataLayerVuexPlugin from 'Plugins/dataLayer/dataLayer'
import { get } from 'Utils/persistantStore'
export const apiFetchStore = async ({ commit, url, params = {}, mutationTypes }) => {

    commit(mutationTypes.PENDING)

    try {
        const { data } = await axios.get(url, params)
        commit(mutationTypes.SUCCESS, data)
    } catch (error) {
        commit(mutationTypes.FAILURE, error.message)
    }

}

export const apiFetchStorePost = async ({ commit, url, config = {}, requestBody, mutationTypes }) => {

    commit(mutationTypes.PENDING)

    try {
        const { data } = await axios.post(url, requestBody, config)
        commit(mutationTypes.SUCCESS, data)
    } catch (error) {
        commit(mutationTypes.FAILURE, error.message)
    }

}

function hydrateStore(store, data){
    const storeStateCleanCopy = JSON.parse(JSON.stringify(store.state))
    const newState = objectAssignDeep({},storeStateCleanCopy, data)
    store.replaceState(newState)
}

function breakpointsWatcher(store){
    const getCurrentMediaQueryName = () => {
        let mediaQueryName = window.getComputedStyle(
            document.querySelector( 'body' ), ':before'
        ).getPropertyValue( 'content' ).replace( /\"/g, '' );
        store.dispatch( 'breakpoint/mediaQuery', mediaQueryName );
    };
    window.addEventListener( 'resize', debounce(getCurrentMediaQueryName, 200) );
    getCurrentMediaQueryName();
}

export function setupStore(store, data) {
    const dataWithPersistantStore = objectAssignDeep(get(), data)
    hydrateStore(store, dataWithPersistantStore)
    breakpointsWatcher(store)
    dataLayerVuexPlugin(store)
}
