export default {
    'fr': {
        short: {
            year: '2-digit', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'en': {
        yearMonth : {
            year: '2-digit', month: 'short'
        },
        short: {
            year: '2-digit', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    },
    'de': {
        yearMonth : {
            year: '2-digit', month: 'short'
        },
        short: {
            year: '2-digit', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    }
}
