/**
 * Retourne un label correspondant au nombre de personnes passé en paramètre
 * @param personCount
 * @returns {string}
 */
export function getCapacityLabel(personCount){
    if(personCount <= 3) {
        return "2-3";
    } else if(personCount <= 5) {
        return "4-5";
    } else if(personCount <= 7) {
        return "6-7";
    } else if(personCount == 8) {
        return "8-8";
    } else if(personCount == 9) {
        return "9-9";
    } else if(personCount <= 12) {
        return "10-12";
    } else if(personCount <= 15) {
        return "13-15";
    } else if(personCount <= 20) {
        return "16-20";
    } else if(personCount <= 24) {
        return "21-24";
    } else if(personCount <= 29) {
        return "25-29";
    } else if(personCount <= 34) {
        return "30-34";
    }
    return "35+";
}

/**
 * Retourne le type de séjour lisible correspondant à l'abréviation du type de séjour passé en paramètre
 * @param stayTypeSelected
 * @returns {string}
 */
export function getstayTypeAsTextFromShortStayType(stayType) {
    const map = {
        "wk" 	: "weekend",
        "lw" 	: "long_weekend",
        "mw" 	: "midweek",
        "1w" 	: "1_week",
        "2w" 	: "2_weeks"
    };
    return map[stayType] ? map[stayType] : 'empty'
}

/**
 * Retourne la date d'aujourd'hui au format YYYY-MM-DD
 * @returns {string}
 */
export function getTodayDate() {
    const today   = new Date();
    const year    = today.getFullYear().toString();
    let month     = (today.getMonth() + 1).toString();
    let day       = today.getDate().toString();

    day     = day.length == 1 ? '0' + day : day;
    month   = month.length == 1 ? '0' + month : month;

    return year + "-" + month + "-" + day;
}

/**
 * Calcul le nombre de jour entre 2 dates au format YYYY-MM-DD passées en paramètre
 * @param startDate
 * @param endDate
 * @returns {number}
 */
export function getNbDaysBetweenToDates(startDate, endDate) {
    return ((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)) + 1
}

/**
 * Calcul le nombre de jour entre aujourd'hui et une date au format YYYY-MM-DD passée en paramètre
 * @param startDate
 * @returns {number}
 */
export function getNbDaysToDate(date) {
    const value = parseInt(((new Date(date) - new Date()) / (1000 * 60 * 60 * 24)) + 1);
    return value > 0 ? value : null;
}

/**
 * Retourne le mois textuel et l'année d'une date au format YYYY-MM-DD passée en paramètre
 * @param date
 * @returns {string}
 */
export function getMonthAndyear(date) {
    const baseDate  = new Date(date)
    const year      = baseDate.getFullYear().toString();
    const month     = (baseDate.getMonth() + 1).toString();

    const mapMonth = {
        1 	: "January",
        2 	: "February",
        3 	: "March",
        4 	: "April",
        5 	: "May",
        6 	: "June",
        7 	: "July",
        8 	: "August",
        9 	: "September",
        10 	: "October",
        11 	: "November",
        12 	: "December"
    };


    return year > 2000 ? mapMonth[parseInt(month)] + " " + year : null
}

/**
 * Transforme le pageName en nom complet
 * @param pageName
 * @returns {string}
 */
export function getProperPageNameForGA(pageName) {
    const mapPageName = {
        "Resa" 	        : "Reservation",
        "FicheLocation" : "FicheMaison"
    };
    return mapPageName[pageName] ? mapPageName[pageName] : pageName;
}