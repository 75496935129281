import M from "Constants/mutations-types";
import {getStayDetails} from "API/product";

const state = {
  stay_id : null,
  type : null,
  start : null,
  end : null,
  originalPrice : null,
  price: null,
  group : {
    personCount : null,
    dogCount : null
  },
  deposit : {
    amount : null,
    payableOnSite : null
  },
  leavingHours : "",
  discount: 0,
  note : "",
  urlResource : null
}

const getters = {
  hasDiscount: ({discount}) => discount > 0,
  discountMinus: ({discount}) => -Math.abs(discount),
  hasToUpdate: ({ start, end, type, group }, getters, rootState, rootGetters) => {
    const wantedStay = rootGetters['product/wantedStay']
    const curStaySelected = {
      start,
      end,
      type,
      group
    }
    return !stayMatchWantedStay(curStaySelected, wantedStay)
  },
}
export const mutations = {
  [M.PRODUCT_SELECTEDSTAY_SET](state, stay){
    Object.assign(state, stay);
  },
}

const stayMatchWantedStay = (stay, wantedStay) => {
  return (
    stay.start === wantedStay.start
    && stay.end === wantedStay.end
    && stay.type === wantedStay.type
    && stay.group.personCount === wantedStay.group.personCount
    && stay.group.dogCount === wantedStay.group.dogCount
  )
}


const actions = {
  updateOnlIfMatchWantedGroupAndDate ({ commit, state, rootGetters }, stay) {
    const wantedStay = rootGetters['product/wantedStay']
    if (stayMatchWantedStay(stay, wantedStay)) {
      commit(M.PRODUCT_SELECTEDSTAY_SET, stay);
      if(stay.promo_badge) {
        document.getElementById('badge-promo').classList.remove('hide');
      } else {
        document.getElementById('badge-promo').classList.add('hide');
      }
      if(stay.early_booking_badge) {
        document.getElementById('badge-earlybooking').classList.remove('hide');
      } else {
        document.getElementById('badge-earlybooking').classList.add('hide');
      }
    }else{
      console.warn("updateOnlIfMatchWantedGroupAndDate no match",wantedStay,stay)
    }
  },
  async update ({ dispatch, getters,rootGetters }) {
    if(!getters.hasToUpdate){
      console.warn("no need to update, info match")
      return;
    }

    const wantedStay = rootGetters['product/wantedStay']
    const stay = await getStayDetails({
      IDLocation: wantedStay.location_id,
      personCount: wantedStay.group.personCount,
      dogCount: wantedStay.group.dogCount,
      stayType: wantedStay.type,
      start: wantedStay.start,
      end: wantedStay.end,
    })
    dispatch('updateOnlIfMatchWantedGroupAndDate', stay)
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}
