import Glide, { Controls, Swipe, Keyboard } from '@glidejs/glide/dist/glide.modular.esm'

export const ArrowDisabler = (Glide, Components) => {
    return {
        mount() {
            // Only in effect when rewinding is disabled
            if (Glide.settings.rewind) {
                return
            }

            Glide.on(['mount.after', 'run'], () => {
                // Filter out arrows_control
                for (let controlItem of Components.Controls.items) {
                    if (controlItem.className !== 'glide__arrows') {
                        continue
                    }

                    // Set left arrow state
                    const left = controlItem.querySelector('.glide__arrow--left')
                    if (left) {
                        if (Glide.index === 0) {
                            left.setAttribute('disabled', '') // Disable on first slide
                        } else {
                            left.removeAttribute('disabled') // Enable on other slides
                        }
                    }

                    // Set right arrow state
                    const right = controlItem.querySelector('.glide__arrow--right')
                    if (right) {
                        if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
                            right.setAttribute('disabled', '') // Disable on last slide
                        } else {
                            right.removeAttribute('disabled') // Disable on other slides
                        }
                    }
                }
            })
        }
    }
}

// https://github.com/glidejs/glide/issues/298
// voir si un jour la v4 sort c'est prévu d'y implementer la methode
export const prevNextPerView = (glider, nbrOfItems) => {

    const NEXT = ">";

    glider.on('run.before', function(direction) {

        if (!glider.enable || pv <= 1 ) {
            return;
        }

        const pv = glider.settings.perView, idx = glider.index;

        let nextindex;
        if ( direction.direction === NEXT) {
            nextindex = idx+(pv-1);

            if ( nextindex >= (nbrOfItems-pv) ) {
                if ( nextindex === nbrOfItems-1 ) {
                    nextindex = -1;
                } else {
                    nextindex = nbrOfItems-(pv+1);
                }
            }

        } else {
            nextindex = idx-(pv-1);

            if ( idx < pv && idx > 0 ) {
                nextindex = 1;
            } else if ( idx === 0 ) {
                nextindex = nbrOfItems-(pv-1);
            }
        }

        glider.index = nextindex;

    });
}

export const setSliderHouseResults = (elements) => {
    elements.forEach(setSliderHouseResult)
}
function setSliderHouseResult(el){
    new Glide(el,{ rewind : false }).mount({  Controls, Swipe, Keyboard, ArrowDisabler })
}
