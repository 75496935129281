import M from "Constants/mutations-types";

const state = {
    isLoading : false
}

export const mutations = {
    [M.LOADER_START] (state){
        state.isLoading = true
    },
    [M.LOADER_END] (state){
        state.isLoading = false
    }
}

const actions = {
    start : ({commit}) => commit(M.LOADER_START),
    end : ({commit}) => commit(M.LOADER_END)
}

export default {
    namespaced : true,
    state,
    mutations,
    actions
}
