import M from 'Constants/mutations-types'
import { set } from 'Utils/persistantStore'
const state = {
    tld : null,
    lang : "nl",
    isFirstPageLoad : true // l'utilisateur a-t-il déjà chargé une page ? utile pour savoir si script déjà en cache ou si premier chargement
}

const getters = {
    isActiveLang(state) {
        return lang => state.lang === lang.toLowerCase()
    },
    shouldDelayScript({ isFirstPageLoad }){
        return isFirstPageLoad;
    }
}
const mutations = {
    [M.BASE_PAGE_LOADED] (state){
        state.isFirstPageLoad = false
        set({base : {isFirstPageLoad : false} })
    },
}
const actions = {
    pageLoaded : ({commit}, id) => commit(M.BASE_PAGE_LOADED),
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
