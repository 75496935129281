import store from 'Store';

export function addContextualDataLayer (key, value) {
    let contextualDataLayer = window.getContextualDataLayer("all", false);
    if(contextualDataLayer) {
        if(key) {
            contextualDataLayer[key] = value;
            saveContextualDataLayer(contextualDataLayer)
        }
    }
}

export function pushContextualDataLayer(pageType) {
    dataLayer.push(window.getContextualDataLayer(pageType, false));
}

function saveContextualDataLayer(contextualDataLayer){
    if(isLocalStorageSupported()) {
        localStorage.setItem('ContextualDataLayer', JSON.stringify(contextualDataLayer));
    }
}

export function sendInterractionalEvent(category, action, label) {

    dataLayer.push({
        'event': 'event_in_code',
        'custom_value_AE': {
            'gacategory': category,
            'gaaction': action,
            'galabel': label
        }
    });
}

export function sendInterractionalEventGA4({site_section, eventAction, eventLabel}, params = {}) {
    dataLayer.push({
        'event': 'GAEvent',
        'eventCategory' : getPageNameForGA(),
        site_section,
        eventAction,
        eventLabel,
        ...params
    })
}

function getPageNameForGA() {
    if(store.getters['page/onProduct'])
        return "Mdv"
}