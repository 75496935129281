<template>
    <input type='hidden' name='tokenForm' :value='tokenForm' />
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "TokenInput",
        computed:{
            ...mapState('auth',{
                tokenForm : ({ tokenForm }) => tokenForm
            })
        }
    }
</script>
