<template>
    <div class="fullPageSpinner" v-if="show">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { BSpinner } from 'bootstrap-vue'

    export default {
        name: "FullPageSpinner",
        components : {
            BSpinner
        },
        computed : {
            ...mapState('loader', {
                show : ({isLoading}) => isLoading
            })
        }
    }
</script>
