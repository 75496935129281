import { unixToYYYYMMDD } from 'Utils/date'

const getStaysStartDateUnix = (stays) => {
  return stays.map(({start}) => new Date(start).getTime())
}
const getStaysEndDateUnix = (stays) => {
  return stays.map(({end}) => new Date(end).getTime())
}

export const minStayStartDate = (stays) => {
  return unixToYYYYMMDD(Math.min.apply(null,getStaysStartDateUnix(stays)))
}

export const maxStayStartDate = (stays) => {
  return unixToYYYYMMDD(Math.max.apply(null,getStaysStartDateUnix(stays)))
}

export const maxStayEndDate = (stays) => {
  return unixToYYYYMMDD(Math.max.apply(null,getStaysEndDateUnix(stays)))
}
