import C from "Constants"
import M from "Constants/mutations-types";

const state = {
    modalOpen : null,
    generic_content : null,
    generic_title : null
}

export const getters = {
    noModalOpen : ({modalOpen}) => (modalOpen === null),
    isModalOpen : ({modalOpen}) => (modalOpen !== null),

    canOpenMobileModal : ({modalOpen}, getters, rootState, rootGetters) =>  rootGetters['breakpoint/isMobile'] && !modalOpen,

    mr1FormModalShow : (state, getters, rootState) => rootState['mr1']['modalOpen'] && getters['canOpenMobileModal'],
    stayLocalitiesShow : (state, getters, rootState, rootGetters) => rootGetters['mr1/isOpenInputLocalities'] && getters['canOpenMobileModal'],
    stayDatesShow : (state, getters, rootState, rootGetters) => rootGetters['mr1/isOpenInputDates'] && getters['canOpenMobileModal'],
    stayGroupShow : (state, getters, rootState, rootGetters) => rootGetters['mr1/isOpenInputGroup'] && getters['canOpenMobileModal'],

    productStayDatesShow : (state, getters, rootState, rootGetters) => rootGetters['product/isOpenInputDates'] && getters['canOpenMobileModal'],
    productStayGroupShow : (state, getters, rootState, rootGetters) => rootGetters['product/isOpenInputGroup'] && getters['canOpenMobileModal'],
    productStayFeesShow : (state, getters, rootState, rootGetters) => rootGetters['product/isOpenInputFees'] && getters['canOpenMobileModal'],

    loginShow : ({modalOpen}) => modalOpen === C.MODAL_LOGIN,
    signUpShow : ({modalOpen}) => modalOpen === C.MODAL_SIGNUP,

    forgotPasswordShow : ({modalOpen}) => modalOpen === C.MODAL_FORGOT_PASSWORD,
    newPasswordShow : ({modalOpen}) => modalOpen === C.MODAL_NEW_PASSWORD,

    favoritesShow : (state, getters, rootState, rootGetters) => rootGetters['favorites/isOpen'] && getters['canOpenMobileModal'],

    genericShow : ({modalOpen}) => modalOpen === C.MODAL_GENERIC,

};

export const mutations = {
    [M.MODAL_CLOSE] (state){
        state.modalOpen = null
        state.generic_content = null
        state.generic_title = null
    },
    [M.MODAL_OPEN] (state, modal){
        state.modalOpen = modal
    },
    [M.MODAL_SET_GENERIC] (state, { content, title }){
        state.generic_content = content
        state.generic_title = title
    }
};

export const actions = {
    open : ({commit}, {modal}) => commit(M.MODAL_OPEN, modal),
    close : ({commit}) => commit(M.MODAL_CLOSE),

    openLogin : ({dispatch}) => dispatch('open',{ modal : C.MODAL_LOGIN }),
    closeLogin : ({dispatch, state : {modalOpen}}) => (modalOpen === C.MODAL_LOGIN && dispatch('close')),

    openSignUp : ({dispatch}) => dispatch('open',{ modal : C.MODAL_SIGNUP }),
    closeSignUp : ({dispatch, state : {modalOpen}}) => (modalOpen === C.MODAL_SIGNUP && dispatch('close')),

    openForgotPassword : ({dispatch}) => dispatch('open',{ modal : C.MODAL_FORGOT_PASSWORD }),
    closeForgotPassword : ({dispatch, state : {modalOpen}}) => (modalOpen === C.MODAL_FORGOT_PASSWORD && dispatch('close')),

    openNewPassword : ({dispatch}) => dispatch('open',{ modal : C.MODAL_NEW_PASSWORD }),
    closeNewPassword : ({dispatch, state : {modalOpen}}) => (modalOpen === C.MODAL_NEW_PASSWORD && dispatch('close')),

    openGeneric : ({commit, dispatch}, content, title) => {
        commit(M.MODAL_SET_GENERIC,{ content, title })
        dispatch('open', { modal : C.MODAL_GENERIC })
    },
    closeGeneric : ({dispatch, state : {modalOpen}}) => (modalOpen === C.MODAL_GENERIC && dispatch('close')),

}

export default {
    namespaced : true,
    state,
    mutations,
    getters,
    actions
}
