import Vue from 'vue'
import lazyLoad from 'Utils/lazyLoad'
import BaseBind from 'Utils/BaseBindClass'
import FullPageSpinner from 'Components/container/FullPageSpinner'
import { setCurSearchNumberOnFocus, setSearchNumberCookie } from 'Utils/search/searchNumberSetter'
import GenericModal from 'Components/container/GenericModal/GenericModal'

/**
 * Class reprenant le code exécuté sur toutes les pages
 */
export default class Global extends BaseBind{
    constructor({ store, i18n, data }){
        super({ store, i18n }).init()

        this.data = data

        this.updateLazyLoad()

        this.initSejourUrl()
        this.initFullPageSpinner()
        setSearchNumberCookie()
        setCurSearchNumberOnFocus()

        this.createGenericModal();
        this.openGenericModalIfNeeded(data)
    }
    updateLazyLoad(){
        lazyLoad.update();
    }

    initSejourUrl(){
        const elements = document.querySelectorAll(".sejourUrl");
        elements.forEach(el => el.setAttribute('href', el.dataset.urlsejour))
    }

    initFullPageSpinner(){
        new Vue({
            el: '#fullPageSpinner',
            store : this.store,
            render(createElement) {
                return createElement(FullPageSpinner);
            },
            components: { FullPageSpinner }
        });
    }

    createGenericModal(){

        new Vue({
            el: '#genericModal',
            store : this.store,
            render(createElement) {
                return createElement(
                    GenericModal
                );

            },
            components: { GenericModal }
        });


    }
    openGenericModalIfNeeded({generic_modal_content, generic_modal_title}){
        if(generic_modal_content){
            this.store.dispatch("modals/openGeneric",generic_modal_content, generic_modal_title);
        }
    }
}
