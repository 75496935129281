import modals from './modals'

const INPUT  = {
    STAY_LOCALITIES : 'localities',
    STAY_DATES : 'dates',
    STAY_GROUP : 'group',
}

export const inputModal  = {
    [INPUT.STAY_LOCALITIES] : modals.MODAL_STAYLOCALITIES,
    [INPUT.STAY_DATES]: modals.MODAL_STAYDATES,
    [INPUT.STAY_GROUP] : modals.MODAL_STAYGROUP,
}


export default {
    ...INPUT
}
