import M from 'Constants/mutations-types'

const state = {
  personCount : 4,
  dogCount : 0
}

const getters = {
  stayGroup: ({personCount, dogCount}) => ({personCount, dogCount}),
}
const mutations = {
  [M.PRODUCT_STAYGROUP_SET_PERSONCOUNT](state, personCount){
    state.personCount = personCount
  },
  [M.PRODUCT_STAYGROUP_SET_DOGCOUNT](state, dogCount){
    state.dogCount = dogCount
  },
}
const actions = {
  setPersonCount({commit}, personCount){
    commit(M.PRODUCT_STAYGROUP_SET_PERSONCOUNT, personCount)
  },
  setDogCount({state, commit}, dogCount){
    commit(M.PRODUCT_STAYGROUP_SET_DOGCOUNT, dogCount)
  },
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}
