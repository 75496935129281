import i18n from 'Plugins/i18n';

const state = {
    langLinks : null,
    helpCenterLink : '/help-center',
    helpCenterOwnerLink : '/help-center-proprietaire#helpcenterContact',
    customerLoginLink : null,
    customerLogoutLink : null
}

export const getters = {
    helpCenterLink: ({ helpCenterLink, helpCenterOwnerLink }, getters, { auth : {isOwner} }) => {
        const label = i18n.t(`__AEPro_Contactez-nous`)
        const url = isOwner
          ? helpCenterOwnerLink
          : helpCenterLink
        return { url,label }
    },
    ownerLink : (state, getters, rootState, rootGetters) => {
        const label = i18n.t(`__AEV5_Je suis un proprietaire`)
        const url = rootGetters['base/lang'] === 'nl'
            ? '/eigenaars/nl/index.do'
            : '/proprietaires/fr/index.do';
        return { label, url }
    },
    logoutLink : ({ customerLogoutLink }) => ({
        url : customerLogoutLink,
        label : i18n.t(`__AEV5_Se déconnecter`)
    }),
    accountLinks : (state, getters) => {
        return [
            getters.guest,
            getters.myAccount,
            getters.myFavorites,
            getters.myContactDetail,
            getters.myBenefits,
            getters.helpCenter,
        ]
    },
    guest : () => ({
        url : "/guest",
        label : i18n.t(`__Accueil`)
    }),
    myAccount : () => ({
        url : "/guest/bookings?type=soon",
        label : i18n.t(`__Mes séjours`)
    }),
    myFavorites : () => ({
        url : "/guest/favorites",
        label : i18n.t(`__Mes favoris`)
    }),

    myBenefits : () => ({
        url : "/guest/vouchers/privilege",
        label : i18n.t(`__Mes avantages`)
    }),
    helpCenter : () => ({
        url : "/help-center",
        label : i18n.t(`__Help center`)
    }),
    oldBooking : () => ({
        url : "/historiqueReservation.do",
        label : i18n.t(`__AEV5_RDAE_Historique de mes réservations`)
    }),
    myContactDetail : () => ({
        url : "/guest/profile",
        label : i18n.t(`__Mon profil`)
    }),
    myNewsletter : () => ({
        url : `/displayNewsLetter.do`,
        label : i18n.t(`__AEV5_Mes Newsletter`)
    }),
    myVouchers : () => ({
        url : "/displayChequeCadeau.do",
        label : i18n.t(`__AEV5_chequeCadeauMenu`)
    }),

}

export default {
    namespaced : true,
    getters,
    state,
}
