import M from 'Constants/mutations-types'

const state = {
    toasts: [],
    counter: 0
};

export const getters = {
    getCounter : ({counter}) => {
        return counter
    },
};

const mutations = {
    [M.TOAST_ADD] (state, toast)
    {
        if (!( state.toasts && Array.isArray(state.toasts))){
            state.toasts= [];
        }
        state.toasts.unshift(toast);

    } ,
    [M.TOAST_INCREASE](state) {
        state.counter++;
    },
    [M.TOAST_REMOVE](state, id){
        if ( state.toasts) {
            const result = state.toasts.filter(toast => toast.id != id);
            state.toasts = result;
        }
    },
};

const actions = {
    addToast: ({ commit, state:{counter}  }, toast) => {
        toast.variant = toast.variant || "danger";
        toast.id = counter;
        commit(M.TOAST_ADD, toast);
        commit(M.TOAST_INCREASE);
        setTimeout(() => {commit(M.TOAST_REMOVE, toast.id)}, 50000);
    },
    removeToast: ({ commit}, payload ) => {
        commit(M.TOAST_REMOVE, payload)
    },
};

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions,
};
