import axios from 'axios'
import qs from 'querystring'

export const getAvailableStay = async (params) => {
    const requestBody = qs.stringify({...params})
    const { data } =  await axios.get('/getSejourAjax.do?' + requestBody)
    return data.tabSejour
}

export const getRentalFeesForStayHtml = async ({stay_id, personCount = null, dogCount = null}) => {
    const requestBody = qs.stringify({
        stay_id,
        personCount,
        dogCount
    })
    const { data } = await axios.get('/rentalFeesForStay.do?' + requestBody)
    return data
}

export const getRentalFeesForStayWithPriceHtml = async ({stay_id, personCount = null, dogCount = null}) => {
    const requestBody = qs.stringify({
        IDSejour : stay_id,
        nbrPers : personCount,
        nbrAnim : dogCount
    })
    const { data } = await axios.get('/chargesLocation.do?' + requestBody)
    return data
}

export const getStayDetails = async (params) => {
    const requestBody = qs.stringify({...params})
    const { data } =  await axios.get('/getStayDetails.do?' + requestBody)
    return data
}
