import C from 'Constants'

const state = {
    page: 'home'
}

const getters = {
    onHome : ({ page }) => page === C.PAGE_HOME,
    onResearch : ({ page }) => page === C.PAGE_RESEARCH || page === C.PAGE_LANDING,
    onBooking : ({ page }) => page === C.PAGE_BOOKING,
    onProduct : ({ page }) => page === C.PAGE_PRODUCT,
    onAccount : ({ page }) => page === C.PAGE_ACCOUNT,
    onFavorites : ({ page }) => page === C.PAGE_FAVORITES,
}

export default {
    namespaced : true,
    state,
    getters
}
