import axios from 'axios'
import { wrapRequest } from './helpers'

const fetchStayDates = () => axios.get('api/stayDates' )
export const wrappedFetchStayDates = wrapRequest(fetchStayDates);

const fetchLocalities = () => axios.get('localities', query )
export const wrappedFetchLocalities = wrapRequest(fetchLocalities);

const fetchStayMonths = () => axios.get('stayMonths', query )
export const wrappedFetchStayMonths = wrapRequest(fetchStayMonths);


