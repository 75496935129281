import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration, Dedupe as DedupeIntegration } from '@sentry/integrations';
import store from "Store";
import C from 'Constants';
let isSetup = false

export const setupErrorHandler = () => {
    if(isSetup)
        return;

    if(ENVIRONMENT === C.DEV){
        isSetup = true
        return;
    }

    Sentry.init({
        ignoreErrors: [
          'Non-Error promise rejection captured',
          'TypeError: Failed to fetch',
          'TypeError: NetworkError when attempting to fetch resource.'
        ],
        environment: ENVIRONMENT,
        release: 'aev5-js@' + COMMITHASH,
        dsn: 'https://6a1136ccffb44eb6b655e62f5683db99@o220821.ingest.sentry.io/4487740',
        // Filter les page sauvée en local (C:/xxx.html)
        allowUrls : [
            /https?/,
        ],
        integrations: [
            new VueIntegration({
                Vue,
                attachProps: true,
                logErrors : true
            }),
          new DedupeIntegration()
        ],
        beforeSend : (event, hint) => {

            if(hint){
                // Filter recaptcha error timeout
                // https://github.com/getsentry/sentry-javascript/issues/2514
                if (hint.originalException === "Timeout")
                    return null;
            }

            if(!event.extra){
                event.extra = {}
            }

            const dataForDebug = store.getters['dataForDebug']

            Object.keys(dataForDebug)
                .forEach(key => {
                    event.extra[key] = dataForDebug[key]
                })

            if(store.getters['auth/isLoggedIn']){
                event['user'] = { id: store.getters['auth/userId'] }
            }
            return event;
        }
    });

    isSetup = true

}

/*const setExtraData = new Promise((resolve) => {
    Sentry.configureScope(scope => {
        const dataForDebug = store.getters['dataForDebug']
        Object.keys(dataForDebug)
            .forEach(key => scope.setExtra(`state.${key}`, dataForDebug[key]))

        if(store.getters['auth/isLoggedIn']){
            scope.setUser({ id: store.getters['auth/userId'] })
        }
        resolve()
    })
})*/


export const handleError = (error) => {
    if(!isSetup){
        console.warn("the error handler is not setup")
        return;
    }

    if(ENVIRONMENT === C.PRODUCTION || ENVIRONMENT === C.TEST){
        Sentry.captureException(error)
    }
    
    if(ENVIRONMENT === C.DEV || ENVIRONMENT === C.TEST){
        throw error;
    }

}
