<template>
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
            <b-button type="submit" variant="success" class="float-right" :disabled="disabled">{{ $t('Continuer') }}</b-button>
        </div>
    </div>
</template>

<script>
    import { BButton } from 'bootstrap-vue'

    export default {
        name: "ContinueSubmitButton",
        components:{
            BButton
        },
        props : {
            disabled : {
                type : Boolean,
                default: false
            }
        }
    }
</script>

