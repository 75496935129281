import M from 'Constants/mutations-types'
import i18n from 'Plugins/i18n'


const state = {
  stay : {
    start : null,
    end : null,
    type : null
  }
}

const getters = {
  stay : ({stay}) => stay,
}

export const mutations = {
  [M.PRODUCT_STAYDATE_SET](state, {start, end, type}){
    state.stay = {start, end, type}
  },
}

export const actions = {
  updateDate({commit}, stay){
    commit(M.PRODUCT_STAYDATE_SET, stay);
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}
