export default {
  'fr': {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  'nl': {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  'de': {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  'en': {
    currency: {
      style: 'currency',
      currency: 'EUR',
    }
  }
}
