import M from 'Constants/mutations-types'
import C from 'Constants';

const MOBILE_BREAKPOINT = [C.xs, C.sm]
const DESKTOP_BREAKPOINT = [C.lg, C.xl]

export const state = {
    currentBreakpoint: '',
};

export const getters = {
    currentBreakpoint: ({currentBreakpoint}) => currentBreakpoint,
    isMobile : ({currentBreakpoint}) => MOBILE_BREAKPOINT.includes(currentBreakpoint),
    isNotMobile : ({currentBreakpoint}) => !MOBILE_BREAKPOINT.includes(currentBreakpoint),
    isDesktop : ({currentBreakpoint}) => DESKTOP_BREAKPOINT.includes(currentBreakpoint),
};

export const mutations = {
    [M.BREAKPOINT_CHANGE]: ( state, mediaQueryName ) => {
        state.currentBreakpoint = mediaQueryName;
    },
};

export const actions = {
    mediaQuery: ( { state, commit }, mediaQueryName ) => {
        if(state.currentBreakpoint !== mediaQueryName) {
            commit(M.BREAKPOINT_CHANGE, mediaQueryName);
        }
    },
};


const breakpoint = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default breakpoint;
