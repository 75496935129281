export default class BaseBind {
    $el
    events // [types, selector, method]
    store
    constructor({el, $el, store, i18n}){
        this.setupEl(el, $el)
        this.i18n = i18n;
        this.store = store;
    }
    hasEventButNoElement(el, $el){
        return !el && !$el && this.hasEvents()
    }
    setupEl(el, $el){
        if(this.hasEventButNoElement(el, $el)){
            this.warn("No element passed to BaseBind class and you have to events. Impossible to bind it!");
            return;
        }

        if(el && $el){
            this.warn("Your passing el && $el to baseBind class ! Bad configuration?");
        }

        if(el){
            this.el = el;
            this.set$El()
        }else if($el){
            this.$el = $el
        }
    }
    hasEvents(){
        return (this.events && this.events.length > 0)
    }
    warn(err){
        console.warn(`${this.getParentClass()}:BaseBind : ${err}`);
    }
    getParentClass(){
        return this.constructor.name;
    }

    set$El(){

        if(!this.el){
            this.warn(`Trying to set $el without el!`)
            return;
        }

        this.$el = document.querySelector(this.el);
        if(!this.$el){
            this.warn(`No element found with selector: ${this.el}`)
        }
    }
    init(){
        if(this.hasEvents())
            this.bindEvents()
    }
    reBindEvents(){
        this.bindEvents()
    }
    bindEvents(){
        if(this.hasEvents()){
            this.events.forEach((event) => this.bindEvent(event))
        }
    }
    bindEvent(event){
        const [ types, selectors, method ] = event

        if(Array.isArray(types)) {
            types.forEach(type => this.bindType(type, selector, method))
        }else if(Array.isArray(selectors)){
            selectors.forEach(selector => this.bindType(types, selector, method))
        }else{
            this.bindType(types, selectors, method)
        }
    }
    bindType(type, selector, method){
        if(!this.$el){
            this.warn(`No $el found while trying to bind ${type} from selector ${this.el} ${selector} for method ${method}`)
            return;
        }

        const els = this.$el
            .querySelectorAll(selector)

        if(els.length === 0){
            this.warn(`Aucun element trouvé pour l'event ${type} du sélecteur ${this.el} ${selector} pour la méthode ${method}`)
            return;
        }

        els.forEach(el => el.addEventListener(
            type,
            this[method]
                ? this[method]
                : this.warn(`Aucun methode ${method} pour l'event trouvé ${type} sur le selecteur ${selector}`)
        ))


    }
}
