import datalayerMR1 from "Plugins/dataLayer/dataLayerMr1";
import datalayerFavorites from "Plugins/dataLayer/dataLayerFavorites";
import dataLayerLiveChat from "Plugins/dataLayer/dataLayerLiveChat";
import { sendInterractionalEvent, sendInterractionalEventGA4 } from "Plugins/dataLayer/dataLayerHelper";
import dataLayerProduct from 'Plugins/dataLayer/dataLayerProduct'

export default function dataLayerVuexPlugin(store) {

    initElementEventTracking(store)

    new datalayerMR1(store)
    new datalayerFavorites(store)
    new dataLayerLiveChat(store)
    new dataLayerProduct(store)
}

function initElementEventTracking(store){
    const ElementTracked = document.querySelectorAll('[data-gaaction]');

    for(let i=0; i<ElementTracked.length; i++) {
        ElementTracked[i].addEventListener('click', function (event) {
            if (event.currentTarget.dataset.gasitesection) {
                sendInterractionalEventGA4({
                    'site_section' : event.currentTarget.dataset.gasitesection,
                    'eventAction' : event.currentTarget.dataset.gaaction,
                    'eventLabel' :  event.currentTarget.dataset.galabel
                })
            } else {
                sendInterractionalEvent(
                    event.currentTarget.dataset.gacategory,
                    event.currentTarget.dataset.gaaction,
                    event.currentTarget.dataset.galabel
                )
            }
        }, false);
    }

    listenMr1(store);
}

function listenMr1(store) {
    store.subscribe((m, state) => {
        try {
            const mutationPath = m.type.split('/')
            let mutation = mutationPath[mutationPath.length -1]
            if(mutation === "mr1OpenInput" && m.payload) {
                sendInterractionalEvent(
                    "Header",
                    "Rechercher",
                    "MR1_Interraction - " + m.payload
                )
            }
        } catch(e) {
            throw new Error(`Error dataLayerPlugin : ${e}`)
        }
    })
}
