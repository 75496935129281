<template>
  <b-tooltip :target="id" triggers="hover" placement="bottom">
    <div v-html="title"></div>
  </b-tooltip>
</template>
<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  name: "StayTypeSelector",
  components : {
    BTooltip,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
  }
}
</script>