import C from "Constants"
import M from "Constants/mutations-types";

const state = {
    email : null,
    isExistingUser : false,
    errorMsg : null
}
export const getters = {}
export const mutations = {
    [M.AUTH_LOGIN_OPEN_EXISTING_USER] (state, email){
        state.isExistingUser = true
        state.email = email
    }
};

export const actions = {
    openLoginExistingUser : ({dispatch, commit}, email) => {
        commit(M.AUTH_LOGIN_OPEN_EXISTING_USER, email)
        dispatch('modals/openLogin', null, { root: true })
    },
}

export default {
    namespaced : true,
    state,
    mutations,
    getters,
    actions
}
