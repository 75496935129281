import M from "Constants";

export const prepareErrorMessage = status => {
    // TODO
    switch (status) {
        default:
            return "Une erreur est survenue, essayer de rafraichir la page et vérifier votre connection internte.";
    }
};

const handleError = error => {
    const errorStatus = error ? error.status : error;
    const errorMessage = prepareErrorMessage(errorStatus);
    return errorMessage
    //console.error("error",errorStatus,errorMessage,error)
    //store.dispatch(M.ERROR_ADD, errorMessage);
};

export const wrapRequest = fn => (...params) =>
    fn(...params)
        .then(response => {
            return response.data;
        })
        .catch(error => handleError(error));

