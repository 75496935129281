import M from "Constants/mutations-types";
import { apiFetchStore } from "Store/helpers";
import dayjs from "dayjs"
import { maxStayStartDate, minStayStartDate } from 'Utils/stays/stays'

export const state = {
    data : [],
    isLoading : false,
    isLoaded : false,
    error: false
}

export const getters = {
    minStayStartDate({data}){
        return minStayStartDate(data)
    },
    monthsFromFirstToLastStay({ data }){
        if(data.length === 0)
            return []

        const minMonth = dayjs(minStayStartDate(data)).date(1);
        const maxMonth = dayjs(maxStayStartDate(data)).date(1);

        let curMonth = minMonth
        let safe = 0;
        const months = [curMonth.format("YYYY-MM-DD")]
        while(curMonth.isBefore(maxMonth) && safe < 500){
            curMonth = curMonth.add(1, 'month')
            months.push(curMonth.format("YYYY-MM-DD"))
            safe++;
        }
        return months;
    },
    staysByType({data}) {
        return stayType => data.filter(stay => stay.type === stayType)
    }
}

export const mutations = {
    [M.MR1_STAYDATES_GET_START](state){
        state.isLoading = true
        state.error = false
    },
    [M.MR1_STAYDATES_GET_SUCCESS](state, data){
        state.data = data
        state.isLoaded = true
        state.isLoading = false
    },[M.MR1_STAYDATES_GET_FAILURE](state, error){
        state.isLoading = false
        state.error = error
    }
}

const mutationTypesGet = {
    PENDING : M.MR1_STAYDATES_GET_START,
    SUCCESS : M.MR1_STAYDATES_GET_SUCCESS,
    FAILURE : M.MR1_STAYDATES_GET_FAILURE
}

export const actions = {
    async get({commit, state : {isLoading, isLoaded}}/*{ state, commit, getters, dispatch }*/){

        if(isLoading || isLoaded)
            return;

        await apiFetchStore({
            commit,
            url : "/ApiStayDates.do",
            mutationTypes : mutationTypesGet
        });

    }
}

export default {
    namespaced : true,
    state,
    mutations,
    actions,
    getters
}
