import M from "Constants/mutations-types";
import { addAPIFavorites, removeAPIFavorites } from "API/favorites";
import Vue from "Mixins/InputDefaultBehavior/InputDefaultBehavior";

export const state = {
    isOpen : false,
    list : {}
}
export const getters = {
    canOpenPopover : (state, getters, rootState, rootGetters) =>  rootGetters['breakpoint/isNotMobile'] && rootGetters['modals/noModalOpen'],
    isOpen : ({isOpen}) => isOpen === true,
    showPopover : ({isOpen}, getters) =>  getters['canOpenPopover'] && getters['isOpen'],
    getList : ({list}) => list,
    countFavorites : ({list}) => Object.keys(list).length,
    isInFavorite : ({list}) => location_id => list[location_id] !== undefined

}

export const mutations = {
    [M.FAVORITES_HOUSE_OPEN](state){
        state.isOpen = true
    },
    [M.FAVORITES_HOUSE_CLOSE](state){
        state.isOpen = false
    },
    [M.FAVORITES_HOUSE_REMOVE](state, {IDLocation}){
        const {[IDLocation]: toRemove, ...without} = state.list
        state.list = without
    },
    [M.FAVORITES_HOUSE_ADD](state,payload){
        const newList = {...state.list, [payload.IDLocation]: payload}
        state.list = newList
    }
}

function dispatchErrorMsg(dispatch, message){
    dispatch('toaster/addToast', { message }, {root:true})
}

export const actions = {
    openFavorites : ({commit}) => commit(M.FAVORITES_HOUSE_OPEN),
    closeFavorites : ({commit}) => commit(M.FAVORITES_HOUSE_CLOSE),

    toggleFavorites : ({commit, state : {isOpen}}) => {
        isOpen
            ? commit(M.FAVORITES_HOUSE_CLOSE)
            : commit(M.FAVORITES_HOUSE_OPEN)
    },
    removeFavorites : ({commit, dispatch, state : {list}},payload) => {

        const toRemove = list[payload.IDLocation]
        if(!toRemove){
            console.warn(`Aucune location trouvé pour l'id ${payload.IDLocation}`)
            return;
        }

        removeAPIFavorites(toRemove)
            .then(({data}) => {
                if(data.msgErreur){
                    throw new Error(data.msgErreur)
                }
                commit(M.FAVORITES_HOUSE_REMOVE, toRemove);
            }).catch(error => dispatchErrorMsg(dispatch , error.message));
    },
    addFavorites : ({commit, dispatch},payload) => {
        addAPIFavorites(payload)
            .then(({data}) => {
                if(data.msgErreur){
                    throw new Error(data.msgErreur)
                }
                commit(M.FAVORITES_HOUSE_ADD, data.model);
            })
            .catch(error => dispatchErrorMsg(dispatch , error.message));
    },
    toggleFavorite : ({dispatch, state : {list}}, payload) => {
        const isInFavorites = list[payload.IDLocation]
        isInFavorites
            ? dispatch('removeFavorites', payload)
            : dispatch('addFavorites', payload)
    }
}



export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
