<template>
  <b-modal
      @hide="closeModal"
      :visible="isVisible"
      hide-footer
      :title="title"
      centered
  >
    <div style="padding:20px;" v-html="content"></div>
  </b-modal>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import { BModal } from 'bootstrap-vue'

  export default {
    name: "GenericModal",
    components: {
      BModal
    },
    computed: {
      ...mapGetters({
        isVisible : 'modals/genericShow',
      }),
      ...mapState('modals',{
        content : ({ generic_content }) => generic_content,
        title : ({ generic_title }) => generic_title
      })
    },
    methods : {
      ...mapActions({
        closeModal: 'modals/closeGeneric',
      }),
    },
  }
</script>
